.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.drop_down{
  background-color: #BDE3CD;
  border-radius: 6px;
  color: #023220;
  font-weight: 600;
  padding: 2px;
  max-height: 110px;
  min-height: 30px;
  min-width:70;
  cursor: pointer;
}

.drop_down1{
  background-color: #F5E7BF;
  border-radius: 6px;
  color: #023220;
  font-weight: 600;
  padding: 2px;
  max-height: 130px;
  min-height: 30px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
  padding: 2px;
  overflow-x: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 8px;
  overflow-x: hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #28b8dc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: 'black';
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
